<template>
  <app-side-menu
    :min-width="18"
    :fixed-menu="false">
    <aside
      class="menu"
      slot="side-menu">
      <p class="menu-label">
        <span class="title is-6">
          Filters
        </span>
      </p>
      <ul class="menu-list">
        <li>
          <div class="field">
            <div class="control">
              <input
                class="input is-capitalized"
                type="text"
                placeholder="Insurer Name"
                v-model="value.name"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <input
                class="input is-capitalized"
                type="text"
                placeholder="Suburb"
                v-model="value.suburb"
                @keyup.enter="onFilterClick()">
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.state">
                  <option value="">-- State --</option>
                  <option
                    v-for="(s) in stateList"
                    :key="s.code"
                    :value="s.name">
                    {{ s.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.labourType">
                  <option value="">-- Labour Times --</option>
                  <option
                    v-for="(labourType, propertyName, index) in labourTimesTypeEnum"
                    :value="propertyName"
                    :key="index">
                    {{ labourType }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.ecomType">
                  <option :value="ecomTypeEnum.None">-- Ecom Type--</option>
                  <option :value="ecomTypeEnum.ORM">ORM</option>
                  <option :value="ecomTypeEnum.PNET">PNET</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <div class="control">
              <div class="select">
                <select v-model="value.quotingMethod">
                  <option value="">-- Quote Method --</option>
                  <option value="H">Hour</option>
                  <option value="D">Dollar</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field has-addons">
            <bulma-radio-button
              :native-value="0"
              v-model="value.isActive">
              <span class="icon is-small">
                <i class="mdi mdi-adjust" />
              </span>
              Both
            </bulma-radio-button>
            <bulma-radio-button
              type="is-success"
              :native-value="1"
              v-model="value.isActive">
              <span class="icon is-small">
                <i class="mdi mdi-check" />
              </span>
              Active
            </bulma-radio-button>
            <bulma-radio-button
              type="is-danger"
              :native-value="2"
              v-model="value.isActive">
              <span class="icon is-small">
                <i class="mdi mdi-close" />
              </span>
              In-Active
            </bulma-radio-button>
          </div>
        </li>
      </ul>
      <hr>
      <div class="field is-grouped is-pulled-right">
        <div class="control">
          <button
            class="button is-primary"
            @click="onFilterClick">
            <span class="icon">
              <i class="mdi mdi-magnify mdi-18px" />
            </span>
            <span>Filter</span>
          </button>
        </div>
        <div class="control">
          <button
            class="button"
            @click="onResetClick">
            <span class="icon">
              <i class="mdi mdi-refresh mdi-18px" />
            </span>
            <span>Reset</span>
          </button>
        </div>
      </div>
    </aside>
  </app-side-menu>
</template>

<script>
import AppSideMenu from '@/components/AppSideMenu'
import { BulmaRadioButton } from '@/components/BulmaRadio'
import InsurerService from './InsurerService'
import { LabourTimeTypes, EcomTypes } from '@/enums'

export default {
  name: 'InsurerListSideMenu',
  components: {
    AppSideMenu,
    BulmaRadioButton
  },
  props: {
    value: Object
  },
  data() {
    return {
      selectedActive: 1,
      stateList: null
    }
  },
  computed: {
    ecomTypeEnum: function() {
      return EcomTypes
    },
    labourTimesTypeEnum() {
      return LabourTimeTypes
    }
  },
  watch: {
    value: {
      handler: function(newVal, oldVal) {
        this.$emit('input', newVal)
      },
      deep: true
    }
  },
  created() {
    this.getStateListCombo()
  },
  methods: {
    onFilterClick() {
      this.$emit('filter')
    },
    onResetClick() {
      this.$emit('reset')
    },
    async getStateListCombo() {
      this.stateList = await InsurerService.getStateListComboProto()
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
